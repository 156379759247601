import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({ data }) => {

  // Process data
  const lessonInfo = {
    body: data.LessonInfo.body, 
    ...data.LessonInfo.frontmatter
  }
  const courseInfo = {
    slug: data.CourseInfo.frontmatter.slug,
    ...data.CourseInfo.exports.coursedata,
    body: data.CourseInfo.body
  }
  const lessonList = data.LessonList.nodes.reduce((acc, cur) =>
  acc.concat(cur.frontmatter), [])

  const pageInfo = {
    type: lessonInfo.type,
    courseId: lessonInfo.courseId,
    sectionId: lessonInfo.sectionId,
    lessonId: lessonInfo.lessonId,
  }

  let seoTitle = "";
  if (pageInfo.type === "course") {
    seoTitle = `${courseInfo.courseTitle}`
  } else if (pageInfo.type === "lesson") {
    seoTitle = `${lessonInfo.lessonTitle} | ${courseInfo.courseTitle}`
  } else {
    seoTitle = 'Open Cantonese: Learn Cantonese for free'
  };

  return (
    <div>
      <SEO title={seoTitle} />
      <Layout 
        pageInfo={pageInfo}
        lessonInfo={lessonInfo}
        courseInfo={courseInfo}
        lessonList={lessonList}
      >
        
      </Layout>
    </div>
  )
}

export default Page

export const query = graphql`
query LessonQuery($slug: String!, $courseId: Int!) {
  LessonInfo: mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      type
      courseId
      lessonId
      lessonTitle
      sectionId
      slug
    }
    body
  }
  CourseInfo: mdx(frontmatter: {type: {eq: "course"}, courseId: {eq: $courseId}}) {
    exports {
      coursedata {
        courseTitle
        courseId
        courseShortTitle
        sections {
          sectionId
          sectionShortTitle
          sectionTitle
        }
      }
    }
    frontmatter {
      slug
    }
    body
  }
  LessonList: allMdx(filter: {frontmatter: {courseId: {eq: $courseId}, type: {eq: "lesson"}}}, sort: {order: ASC, fields: frontmatter___lessonId}) {
    nodes {
      frontmatter {
        lessonTitle
        lessonId
        courseId
        sectionId
        slug
      }
    }
  }
  CourseList: allMdx(filter: {frontmatter: {type: {eq: "course"}}}) {
    edges {
      node {
        exports {
          coursedata {
            courseId
            courseShortTitle
          }
        }
      }
    }
  }
}
`